<template>
  <div key="finish-step" :class="{
      'opacity-0': !visible
    }" class="flex items-stretch min-h-xl transition-opacity">
    <div 
    class="order-1 md:order-0 p-6 md:p-8 lg:p-12 md:w-c6 flex flex-col justify-center items-start">
      
      <div class="mt-0 mb-auto text-14 font-light opacity-85 text-theme-fg">
        Completed <svg-icon icon="circle-tick" class="inline-block align-middle ml-1 -mt-px text-theme-highlight fill-current" size="4" />
      </div>

      <template v-if="askForDemographics">
        <div class="mt-4 max-w-88 text-16 font-light leading-medium">
          <p class="text-28 font-light leading-tight mb-8">Before you go, could you tell us a bit more about yourself?</p>

          <form @submit.prevent="submitDemographics" class="max-w-88" action="/">
            <div class="flex items-center">
              <label class="font-normal text-16 whitespace-no-wrap mr-4">
                Postcode:
              </label>
              <div class="relative flex-1 max-w-50 md:max-w-64 ml-auto">
                <input placeholder="e.g. N1" type="text" class="pl-4 pr-2 border rounded shadow w-full flex-grow border-grey-20 font-light text-16 bg-white h-10 font-sans" v-model="postcode" />
              </div>
            </div>
            <div class="flex items-center mt-2">
              <label class="font-normal text-16 whitespace-no-wrap mr-4">
                Age:
              </label>
              <div class="relative flex-1 max-w-50 md:max-w-64 ml-auto">
                <select class="appearance-none pl-4 pr-2 border w-full rounded shadow border-grey-20 font-light text-16 bg-white h-10 font-sans" v-model="age">
                  <option value=""></option>
                  <option value="under21">Under 21</option>
                  <option value="21-30">21 - 30</option>
                  <option value="31-40">31 - 40</option>
                  <option value="41-50">41 - 50</option>
                  <option value="51-60">51 - 60</option>
                  <option value="over60">Over 60</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-grey-20">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
              </div>
            </div>
            <div class="flex items-center mt-2">
              <label class="font-normal text-16 whitespace-no-wrap mr-4">
                Gender:
              </label>
              <div class="relative flex-1 max-w-50 md:max-w-64 ml-auto">
                <select class="appearance-none pl-4 pr-2 border w-full rounded shadow border-grey-20 font-light text-16 bg-white h-10 font-sans" v-model="gender">
                  <option value=""></option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-grey-20">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
              </div>
            </div>
            <div class="mt-4 text-12 font-light leading-medium">
              <p><em>By submitting this form you, acknowledge having read and understood our <a href="/privacy-policy/" target="_blank" class="text-theme-highlight">Privacy Policy</a>.</em></p>
            </div>
            <div class="flex justify-end w-full mt-2">
              <button type="submit" class="ml-auto mt-4 px-6 text-14 antialiased h-10 italic font-serif font-normal rounded-full bg-white text-theme-highlight border border-theme-highlight hover:bg-theme-highlight hover:text-white cursor-pointer">Submit</button>
            </div>
          </form>
        </div>
      </template>
      <template v-if="!askForDemographics">
        <div class="w-full max-w-94 my-auto">
          <svg-icon icon="party" class="mt-auto mb-2 party-hat" />
          <h2 class="text-28 font-medium mb-2">You're all done!</h2>
          <div class="text-28 font-light leading-tight mb-2">Thanks for completing the poll.</div>
          <template v-if="!complete">
            <div class="mt-4 max-w-88 text-16 font-light leading-medium">
                <p>If you want to keep up to date with the progress of this project then sign up for updates below.</p>
            </div>
            <form @submit.prevent="finish" class="max-w-88 flex items-end w-full mt-8" action="/">
              <label class="font-light text-14 flex-grow flex-shrink">
                <span class="visually-hidden">Enter your email</span>
                <input placeholder="me@example.com" type="email" class="pl-4 pr-2 border w-full flex-grow border-grey-20 font-light text-16 bg-white h-10 font-sans" v-model="email" />
              </label>
              <button type="submit" class="flex-shrink-0 flex-grow-0 px-6 text-14 antialiased h-10 italic font-serif font-normal  bg-blue bg-theme-highlight text-white cursor-pointer">Sign Up</button>
            </form>
          </template>
          <div v-else class="italic text-18 mt-4 font-serif font-normal text-theme-highlight">
              {{ message }}
          </div>
        </div>
      </template>

      <div class="h-px mb-0 mt-auto"></div>
      
    </div>
    <div class="md:order-1 order-0 md:w-c6 self-stretch relative">
      <img :src="image" class="hidden md:block w-full h-full object-center object-cover relative z-0" />
      <router-link id="card-close-button" class="button text-white border-white bg-black-40 absolute right-0 bottom-0 z-1 m-8" to="/">Close</router-link>
    </div>

  </div>
</template>

<script>

import { mapState } from 'vuex'
import {getIsDemographicsFinished, setDemographicsFinished, getIsUserFinished, setUserFinished} from "@/vue/store/_ajax"

export default {
  layout: 'card-overlay',
  data() {
    return {
      locked: false,
      email: '',
      locked: false,
      askForDemographics: true,
      age: '',
      gender: '',
      postcode: '',
      complete: false,
      message: '',
      visible: false,
    }
  },
  watch: {
    email(a) {
      if(a) {
        this.status = ''
      }
    }
  },
  computed: {
    ...mapState( 'polls', [
      'image',
      'slug',
    ]),
  },
  mounted() {
    var APP = window.APP || {};

    this.visible = false
    this.complete = getIsUserFinished()
    this.askForDemographics = ! getIsDemographicsFinished()

    document.body.classList.add('fix-body')
    setTimeout( () => {
      this.visible = true
    }, 400 )
  },
  beforeRouteLeave( to, from, next ) {
    // We're closing...
    if ( to.path === "/" ) {
      document.body.classList.remove('fix-body')
      setTimeout( () => {
        // console.log("Next?")
        next();
      }, 200 );
    } else {
      next();
    }
  },
  created() {
    if ( !this.slug ) {
      this.$store.dispatch('polls/load', this.$route.params.poll);
    }
  },
  methods: {
    checkCanSubmit() {
      if ( !this.email ) {
        return false
      }
      return true
    },
    async submitDemographics() {

      const success = await this.$store.dispatch('question/demographics', {
        postcode: this.postcode,
        age: this.age,
        gender: this.gender,
      })

      if ( success ) {
        setDemographicsFinished();
        this.askForDemographics = false
      } else {
        this.locked = false
      }

    },
    async finish() {

      if( !this.checkCanSubmit() ) return

      const success = await this.$store.dispatch('question/finish', {
        email: this.email,
      })

      if ( success ) {
        setUserFinished()
        this.complete = true
      } else {
        this.locked = false
      }
    }
  },
}
</script>

<style scoped>
.party-hat {
  transform: rotate(-30deg);
  width: 58px;
  height: 57px;
  @apply text-theme-highlight fill-current;
}
</style>