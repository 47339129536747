<template>
  <div class="flex justify-end items-center">
    <div v-if="$slots.default" class="mr-4 text-14 font-light text-red">
      <slot />
    </div>
    <svg-icon v-if="loading" icon="tail-spin" class="text-black mr-2" size="8" />
    <div v-if="loading" class="button text-white  bg-blue bg-theme-highlight">Saving...</div>
    
    <router-link
      class="button text-white bg-blue bg-theme-highlight"
      v-else-if="nextUrl && complete"
      :to="nextUrl"
    >Next</router-link>
    <button
      v-else-if="!complete"
      @click="$emit('submit')"
      class="button text-white bg-blue bg-theme-highlight"
    >Submit</button>
    <router-link class="button text-white bg-blue bg-theme-highlight" v-else-if="final" :to="final">Finish</router-link>
  </div>
</template>

<script>
export default {
  props: ['loading', 'complete', 'nextUrl'],
  computed: {
    final() {
      return this.$store.getters['polls/finalUrl']
    }
  }
}
</script>

<style scoped>
.button {
  width: 150px;
}
</style>