var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex justify-end items-center" },
    [
      _vm.$slots.default
        ? _c(
            "div",
            { staticClass: "mr-4 text-14 font-light text-red" },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c("svg-icon", {
            staticClass: "text-black mr-2",
            attrs: { icon: "tail-spin", size: "8" }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "button text-white  bg-blue bg-theme-highlight" },
            [_vm._v("Saving...")]
          )
        : _vm.nextUrl && _vm.complete
        ? _c(
            "router-link",
            {
              staticClass: "button text-white bg-blue bg-theme-highlight",
              attrs: { to: _vm.nextUrl }
            },
            [_vm._v("Next")]
          )
        : !_vm.complete
        ? _c(
            "button",
            {
              staticClass: "button text-white bg-blue bg-theme-highlight",
              on: {
                click: function($event) {
                  return _vm.$emit("submit")
                }
              }
            },
            [_vm._v("Submit")]
          )
        : _vm.final
        ? _c(
            "router-link",
            {
              staticClass: "button text-white bg-blue bg-theme-highlight",
              attrs: { to: _vm.final }
            },
            [_vm._v("Finish")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }