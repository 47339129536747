<template>
  <div :class="{
      'invisible': total <= 0
    }" class="transition-alpha">
    <div class="flex justify-between text-14 font-light text-black-80">
      <div class>{{ total }} Questions</div>
      <div class="text-right">{{ count }}/{{ total }}</div>
    </div>
    <div class="w-full h-2 bg-lightgrey-d overflow-hidden rounded-lg">
      <div
        :style="{
            transform: `scaleX(${count/total})`,
          }"
        class="bg-blue bg-theme-highlight h-full w-full ml-0 mr-auto scalex-0"
      ></div>
    </div>
  </div>
</template>

<script>
import { get } from "@/vue/store/_ajax"
export default {
  props: ['slug'],
  data() {
    return {
      questions: []
    }
  },
  computed: {
    count() {
      let count = 0
      if ( !this.questions ) return count

      if (this.questions.length) {
        this.questions.forEach(({ answers }) => {
          if (answers.hasAnswered) {
            count += 1
          }
        })
      }

      return count
    },
    total() {
      return this.questions ? this.questions.length : 0
    }
  },
  mounted() {
    this.getPoll()
  },
  methods: {
    async getPoll() {
      const { data: response } = await get('polls/get-poll', { slug: this.slug })
      if (response.success) {
        const { data } = response
        if (data) {
          const { questions } = data
          if ( questions && questions.length ) {
            this.questions = questions
          }
        }
      }
    }
  }


}
</script>

<style scoped>
.scalex-0 {
  transform: scaleX(0);
  transition: transform 0.3s ease;
  transform-origin: left;
}
</style>