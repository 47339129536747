var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "items-center mt-8 md:flex" }, [
        _c("div", { staticClass: "md:w-c5" }, [
          _c(
            "div",
            { staticClass: "w-full max-w-70" },
            [
              _c("base-slide-title", { attrs: { tag: "h2" } }, [
                _c(
                  "div",
                  [
                    _c(
                      "transition",
                      { attrs: { name: "fade-simple", mode: "out-in" } },
                      [
                        _vm.showAnswers
                          ? _c(
                              "div",
                              {
                                key: "showAnswers-true",
                                staticClass: "w-full"
                              },
                              [
                                _vm._v(
                                  "\n              Thanks!\n              "
                                ),
                                _vm.question.resultText
                                  ? _c("span", [
                                      _c(
                                        "span",
                                        { staticClass: "text-theme-highlight" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.sameVotePercentage) + "%"
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.question.resultText) +
                                          "\n              "
                                      )
                                    ])
                                  : _c("span", [
                                      _c(
                                        "span",
                                        { staticClass: "text-theme-highlight" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.sameVotePercentage) + "%"
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        " of people who answered gave this response.\n              "
                                      )
                                    ])
                              ]
                            )
                          : _c(
                              "div",
                              {
                                key: "showAnswers-false",
                                staticClass: "w-full"
                              },
                              [
                                _c("p", [_vm._v(_vm._s(_vm.question.text))]),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "mt-6 font-light text-14 max-w-60 text-grey leading-medium"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Drag and drop to rearrange your answers from high to low priority.\n                "
                                    )
                                  ]
                                )
                              ]
                            )
                      ]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "my-8 md:my-0 md:w-c7 md:pl-8" },
          [
            _c("ChooseOrder", {
              attrs: {
                "top-label": _vm.question.meta.topLabel,
                "bottom-label": _vm.question.meta.bottomLabel,
                disabled: _vm.locked || _vm.showAnswers,
                "fixed-order": _vm.fixedOrder,
                options: _vm.question.options
              },
              on: { "change-order": _vm.changedOrder }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-8 text-right" },
        [
          _c("SaveButton", {
            attrs: {
              loading: _vm.$store.state.question.loading,
              complete: _vm.showAnswers,
              "next-url": _vm.nextUrl
            },
            on: { submit: _vm.submit }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("portal", { attrs: { to: "debug" } }, [
        _c(
          "div",
          { staticClass: "debug" },
          [
            _c("div", { staticClass: "font-bold" }, [_vm._v("Emitted Order:")]),
            _vm._v(" "),
            _vm._l(_vm.chosenOrder, function(question, i) {
              return _c("div", { key: i }, [
                _vm._v(_vm._s(question.id) + " - " + _vm._s(question.text))
              ])
            }),
            _vm._v(" "),
            _c("div", [_vm._v(_vm._s(_vm.fixedOrder))])
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }