var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "transition-alpha",
      class: {
        invisible: _vm.total <= 0
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "flex justify-between text-14 font-light text-black-80"
        },
        [
          _c("div", {}, [_vm._v(_vm._s(_vm.total) + " Questions")]),
          _vm._v(" "),
          _c("div", { staticClass: "text-right" }, [
            _vm._v(_vm._s(_vm.count) + "/" + _vm._s(_vm.total))
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "w-full h-2 bg-lightgrey-d overflow-hidden rounded-lg" },
        [
          _c("div", {
            staticClass:
              "bg-blue bg-theme-highlight h-full w-full ml-0 mr-auto scalex-0",
            style: {
              transform: "scaleX(" + _vm.count / _vm.total + ")"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }